@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-gray-700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
input,
h1,
h2,
h3,
p,
button,
textarea {
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.5px !important;
}

h1,
h2,
h3 {
  font-weight: 400 !important;
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif !important;
}

h1 {
  font-size: 28px;
}

p {
  font-size: 1.1em;
  line-height: 1.8em;
}

div.section {
  margin-bottom: 35px;
}

code {
  color: #e83e8c;
  word-break: break-word;
  font-size: 87.5%;
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono',
    'Courier New', monospace;
  background-color: #f8fafc;
  padding: 4px;
  border-radius: 5px;
}

h3 {
  text-transform: uppercase !important;
  font-size: 10pt !important;
}

h1,
h2,
h3 {
  color: var(--black) !important;
}

a {
  color: var(--primary) !important;
}

input::-webkit-calendar-picker-indicator {
  color: #fff;
}

i.outline {
  outline-style: none;
}

@layer utilities {
  [type='number']::-webkit-inner-spin-button,
  [type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  [type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
}

.red {
  color: var(--red);
}

.highlight {
  color: var(--primary);
}

.solidBorderTop {
  border-top: solid 1px var(--primary-light);
}

textarea::-webkit-input-placeholder {
  color: #c3cad2;
}

textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #c3cad2;
}

textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #c3cad2;
}

textarea:-ms-input-placeholder {
  color: #c3cad2;
}

textarea::placeholder {
  color: #c3cad2;
}

textarea.error::-webkit-input-placeholder {
  color: #e6bdbc;
}

textarea.error:-moz-placeholder {
  /* Firefox 18- */
  color: #e6bdbc;
}

textarea.error::-moz-placeholder {
  /* Firefox 19+ */
  color: #e6bdbc;
}

textarea.error:-ms-input-placeholder {
  color: #e6bdbc;
}

textarea.error::placeholder {
  color: #e6bdbc;
}
