.ui.dropdown .menu .selected.item,
.ui.dropdown .menu > .item:hover {
  background-color: var(--bg-grey) !important;
}

.ui.tabular.menu,
.ui.secondary.pointing.menu {
  border-bottom: 1px solid var(--border-grey) !important;
}

.ui.tabular.menu .active.item {
  border: solid 1px var(--border-grey) !important;
  border-bottom: none !important;
}

.ui.tabular.menu .item:hover {
  filter: brightness(1) !important;
}

.ui.breadcrumb a {
  text-transform: capitalize;
}

.ui.selection.dropdown:not(.no-overrides) a.ui.label {
  padding: 6px 8px;
  background-color: var(--primary) !important;
  color: #fff !important;
  border: none !important;
}

input[disabled]:focus,
input[readonly]:focus,
textarea[disabled]:focus,
textarea[readonly]:focus {
  border-color: inherit !important;
}

input:not([disabled], [readonly], .no-overrides):focus,
textarea:not([disabled], [readonly]):focus,
input:not([disabled], [readonly], .no-overrides):hover,
textarea:not([disabled], [readonly]):hover,
.ui.selection.dropdown:not([disabled], [readonly]):hover,
.ui.selection.dropdown:not([disabled], [readonly]).active {
  box-shadow: var(--active-shadow) !important;
  border-color: var(--primary) !important;
}

.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: var(--primary) !important;
}

.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background-color: var(--primary) !important;
}

textarea.error,
.ui.dropdown.error:hover,
.ui.dropdown.error,
.ui.input.error > input,
.field.error > textarea {
  background-color: #fff !important;
  border-color: var(--error) !important;
  box-shadow: var(--error-shadow) !important;
  color: var(--error) !important;
}

.ui.dropdown.error > .default.text {
  color: #e6bdbc !important;
}

.ui.dropdown.error > .menu > .item {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.menu > a {
  color: rgba(0, 0, 0, 0.6) !important;
}

.ui.menu > a:hover {
  color: rgba(0, 0, 0) !important;
}

button.ui.basic.button,
a.ui.basic.button {
  background-color: #fff !important;
}

.ui.black.button,
.ui.primary.button {
  background-color: var(--primary) !important;
}

.ui.basic.black.button,
.ui.basic.black.button:hover,
.ui.basic.primary.button,
.ui.basic.primary.button:hover {
  color: var(--primary) !important;
}

.ui.label:not(.no-overrides) {
  font-size: 0.9em !important;
  font-weight: lighter !important;
  background-color: var(--bg-grey) !important;
  border: solid 1px var(--border-grey) !important;
}

.ui.modal > .actions {
  background-color: transparent !important;
  border: none !important;
}

.ui.modal > .header {
  border: none !important;
}

.ui.modal {
  padding: 10px;
}

.ui.search .prompt {
  border-radius: 4px !important;
  width: 300px !important;
}

.ui.labeled.icon.button > .icon,
.ui.labeled.icon.buttons > .button > .icon {
  background-color: transparent !important;
}

button.ui.button.border-none.ui.button.border-none {
  box-shadow: none !important;
  padding-left: 2px;
  padding-right: 2px;
}

button.ui.button.border-none.ui.button.border-none {
  box-shadow: none !important;
  padding-left: 2px;
  padding-right: 2px;
}

.menu > .item > a {
  color: rgba(0, 0, 0, 0.87) !important;
}

.ui.fitted.checkbox:not(.toggle, .slider) {
  margin-right: 10px;
}

.ui.fitted.checkbox:not(.toggle, .slider) > label::before {
  width: 23px;
  height: 23px;
  border-radius: 3px;
  border: solid 1px var(--border-grey);
}

.ui.fitted.checkbox.radio:not(.toggle, .slider) > label::before {
  border-radius: 23px;
}

.ui.fitted.checkbox:not(.toggle, .slider):hover > label::before {
  width: 23px;
  height: 23px;
  border-radius: 3px;
  border: solid 1px var(--primary);
  box-shadow: var(--active-shadow);
}

.ui.fitted.checkbox.radio:not(.toggle, .slider):hover > label::before {
  border-radius: 23px;
}

.ui.fitted.checkbox.toggle:hover > label::before {
  box-shadow: var(--active-shadow);
}

.ui.fitted.checkbox:not(.toggle, .slider) > label::after {
  width: 23px;
  height: 23px;
  background-color: var(--primary) !important;
  color: #fff;
  border-radius: 3px;
  font-weight: lighter;
}

.ui.fitted.checkbox.radio:not(.toggle, .slider) > label::after {
  border-radius: 23px;
}

.ui.checkbox:not(.toggle, .slider) input:checked ~ .box:after,
.ui.checkbox:not(.toggle, .slider) input:checked ~ label:after {
  padding: 3px;
  border-color: var(--primary) !important;
}

/**
 * RSW editor doesn't isolate its styles from the parent styles.
 * We need to redefine these styles again:
 * @see https://www.npmjs.com/package/react-simple-wysiwyg?activeTab=readme#user-content-troubleshooting
 */
.rsw-ce {
  background-color: white;
  padding: 15px !important;
  font-size: 16px;
  min-height: 150px !important;
}

.rsw-is-disabled .rsw-ce {
  opacity: 0.8;
  pointer-events: none;
}

.rsw-ce ul,
.rsw-view-mode ul {
  list-style: disc;
  padding-left: 2em;
}

.rsw-ce ol,
.rsw-view-mode ol {
  list-style: decimal;
  padding-left: 2em;
}

.rsw-ce a,
.rsw-view-mode a {
  text-decoration: underline;
  color: var(--primary);
}

.rsw-ce:focus {
  outline: none !important;
}

.rsw-editor:not(.no-overrides):has(.rsw-ce:focus) {
  box-shadow: var(--active-shadow);
  border-color: var(--primary);
}

.rsw-has-error .rsw-editor.rsw-editor {
  background-color: #fff;
  border-color: var(--error);
  box-shadow: var(--error-shadow);
  color: var(--error);
}

.rsw-btn {
  line-height: inherit;
}

.rsw-is-disabled .rsw-btn {
  pointer-events: none;
  cursor: auto;
  opacity: 0.4;
}

textarea {
  resize: none;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
}
