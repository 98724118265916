#sidebar {
  border-right: dotted 1px var(--border-grey);
  border-bottom: dotted 1px var(--border-grey);
  border-radius: 0px 0px 5px 0px;
  padding-top: 10px;
  padding-bottom: 10px;
  position: fixed;
  min-width: 63px;
  z-index: 999;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#sidebar .sideMenuItem {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(97, 96, 95) !important;
  font-size: 21px;
  font-weight: lighter;
  border-radius: 5px;
  cursor: pointer;
  height: 47px;
}

#sidebar .menu .item {
  color: rgb(97, 96, 95) !important;
}

#sidebar .sideMenuItem:hover,
#sidebar .sideMenuItem.active {
  transform: scale(1.1);
  transition: 0.3s;
}

#sidebar .sideMenuItem:hover,
#sidebar .sideMenuItem.active,
#sidebar .menu .item.active {
  color: var(--primary) !important;
}

#sidebar .disabled {
  filter: brightness(2);
}

#sidebar .disabled:hover {
  cursor: not-allowed !important;
  color: rgb(97, 96, 95) !important;
}

#sidebar > hr {
  width: 70%;
  margin: 7px auto;
}

#sidebar > div.logo:hover img {
  border: solid 2px var(--primary) !important;
}

#sidebar .icon {
  margin: 0;
}
