:root {
  --bg-grey: #f8f8f8;
  --bg-greylight: #f1f5f9;
  --border-grey: #ddd;
  --hl-grey: #f8fafc;
  --hl-color: #0091ae;
  --bg-dark: #334155;
  --primary: #4355be;
  --primary-light: #f6f4ff;
  --border-primary: #eeeaff;
  --red: #db2828;
  --olive: #8abc1e;
  --green: #6a803d;
  --black: #1e212a;
  --south: #ed151e;
  --error: #e23431;
  --active-shadow: 0px 0px 10px 1px var(--primary);
  --error-shadow: 0px 0px 4px 1px var(--error);
  --rotten: #fff5ee;
}
